body {
  font-family: 'Open Sans', sans-serif;
  background-color: rgb(13, 13, 15);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.MuiLinearProgress-colorPrimary {
  height: 14px !important;
  border-radius: 10px !important;
}

.homePageWrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-image: url("./assets/dark-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
  width: 100%;
}

.homeSidebarImg {
  position: fixed;
  right: -9rem;
  max-width: 800px;
  height: 800px;
  bottom: 0px;
}

.homeSidebarImg img {
  width: 100%;
  height: 100%;
}

.documentCamera .videoCamera {
  height: 343px !important;
}

.MuiLinearProgress-bar {
  background-color: green !important;
}
.MuiLinearProgress-root {
  background-color: rgb(0 128 0 / 20%) !important;
}

.green-border {
  border: 3px solid #2ba24c;
  height: 187px;
  width: 165px;
  position: absolute;
  right: 5rem;
  bottom: 27rem;
  z-index: 1;
}

.green-border p {
  color: #2ba24c;
  margin: 0px;
  position: absolute;
  bottom: -27px;
  left: 0;
  width: 100%;
  text-align: center;
}

.datePickerWrap > div {
  width: 100%;
}

.videoCamera {
  /* border: 4px solid green !important; */
  width: 100% !important;
}

.AgeItem {
  transition: all 0.5s;
  justify-content: center !important;
}
.AgeItem:last-child {
  border: none !important;
}
.AgeItem:hover {
  background-color: #ff9900 !important;
  color: #fff !important;
}

.overlayLoader {
  position: absolute;
  width: 100.6%;
  left: 0px;
  top: 0px;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.overlayLoader span {
  color: #fff !important;
}

.cameraSelect {
  width: 74% !important;
}

.cameraSelect .MuiSelect-select {
  padding: 10px 13px !important;
  padding-right: 36px !important;
}

.overflowUnset {
  overflow: unset !important;
}

.googleBackground {
  position: absolute;
  /* z-index: 1; */
}

/* Spinner Loader */
.loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6), -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6), -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(0, 0, 0, 0.6), -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.6), -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(0, 0, 0, 0.6),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.6), 2.5em 0em 0 0em rgba(0, 0, 0, 0.6),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.6), 0em 2.5em 0 0em rgba(0, 0, 0, 0.6),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

/* Spinner Loader End */

@media only screen and (max-width: 767px) {
  .homeSidebarImg {
    display: none;
  }
  .green-border {
    display: none;
  }
  .overlayLoader{
    height: 76%;
  }
  br {
    content: "";
  }
  .cameraSelect {
    width: 234px !important;
  }
  .cameraSelect .MuiSelect-select {
    padding: 4px 13px !important;
  }
  .mainCameraSelectWrap {
    margin-bottom: 5px !important;
    margin-top: 8px !important;
  }
  .cardHeadingNoMargin {
    margin-left: 0px !important;
  }
  .headerLogo {
    width: 80px;
  }
  .homePageWrapper {
    height: unset;
    padding-top: 80px;
  }
  body {
    background-attachment: fixed;
  }
  .homeComponent {
    height: calc(100vh - 160px);
  }
  .homeComponent > div {
    height: 100%;
  }
  .centralLogo {
    width: 90px;
    margin-left: auto;
    height: 35px;
  }
}

@media only screen and (max-width: 1400px) {
  .homeSidebarImg {
    height: 650px;
  }
  .green-border {
    right: 1rem;
    bottom: 21rem;
  }
}
